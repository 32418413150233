import { Axios } from "../Config";

const Index = {
  index: (data) =>
    Axios.get(`/api/services/index`, { params: { ...data } }).then(
      (res) => res
    ),
  show: (id) => Axios.get(`/api/services/show/${id}`).then((res) => res),
  list: () => Axios.get(`/api/services/list`).then((res) => res),
};

export default Index;
