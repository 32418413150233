import React from "react";
import { GrLanguage } from "react-icons/gr";
import { Languages } from "../../Config";
import azFlag from "../../assets/images/azFlag.png";
import engFlag from "../../assets/images/engFlag.png";

const HeaderLangs = () => {
  const clickLang = (lang) => {
    Languages.ChangeLanguage(lang);
  };
  return (
    <li className="lang__li flex items-center relative  h-full group  pl-4  ">
      <GrLanguage />
      <ul className="headerlangs hidden hover:!block group-hover:block w-36">
        {Languages.languages.map((language, index) => (
          <li
            key={index}
            className="headerlangs__li hover:underline cursor-pointer flex justify-start items-center gap-1"
            onClick={() => clickLang(language?.code)}
          >
            <img
              src={
                language?.code === "az"
                  ? azFlag
                  : language?.code === "eng"
                  ? engFlag
                  : azFlag
              }
              alt="az-flag"
              className="w-6 h-3"
            />
            {language?.name}
          </li>
        ))}
      </ul>
    </li>
  );
};

export default HeaderLangs;
