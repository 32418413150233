import React from "react";
import { Link } from "react-router-dom";
import { Languages } from "../../Config";
import {
  FaFacebook,
  FaInstagram,
  FaWhatsapp,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";

const innerText = Languages.SelectLanguage();

const Footer = ({ settingsFilter }) => {
  const unvan = settingsFilter("unvan");
  const telefon1 = settingsFilter("telefon1");
  const mail = settingsFilter("mail");

  return (
    <div className="bg__blue pt-14">
      <div className="container">
        <div className=" flex justify-between gap-x-20 max-md:flex-col max-md:gap-y-10">
          <div className="basis-3/12 md:pl-10">
            <h2 className="font-bold text-xl text-white">Menu</h2>
            <ul className="text-white flex flex-col mt-3 leading-8">
              <Link to="/"> {innerText?.["Əsas səhifə"]} </Link>
              <Link to="/about"> {innerText?.["Haqqımızda"]}</Link>
              <Link to="/services">{innerText?.["Xidmətlər"]}</Link>
              <Link to="/products">{innerText?.["Məhsullar"]}</Link>
              <Link to="/blogs">{innerText?.["Blogs"]}</Link>
              <Link to="/contact">{innerText?.["Əlaqə"]}</Link>
            </ul>
          </div>
          <div className="basis-3/12">
            <h2 className="font-bold text-xl text-white">
              {" "}
              {innerText?.["Bizimlə əlaqə"]}
            </h2>
            <ul>
              <p className="text-justify text-white mt-3 leading-7">
                {innerText?.["Ünvan"]}: {unvan}
              </p>
              <li className="text-white">
                {innerText?.["Telefon"]}: {telefon1}
              </li>
              <li className="text-white">
                {innerText?.["Email"]}: {mail}
              </li>
              <li className="text-white mt-2">
                <ul className="flex align-center gap-4">
                  <li>
                    <Link
                      className="text-white text text-2xl"
                      target="_blank"
                      to={settingsFilter("facebook_link")}
                    >
                      <FaFacebook />
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-white text text-2xl"
                      target="_blank"
                      to={settingsFilter("instagram_link")}
                    >
                      <FaInstagram />
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-white text text-2xl"
                      target="_blank"
                      to={settingsFilter("linkedin_link")}
                    >
                      <FaLinkedin />
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-white text text-2xl"
                      target="_blank"
                      to={settingsFilter("whatsapp_link")}
                    >
                      <FaWhatsapp />
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="text-white text text-2xl"
                      target="_blank"
                      to={settingsFilter("youtube_link")}
                    >
                      <FaYoutube />
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      className="text-white text text-2xl"
                      target="_blank"
                      to={settingsFilter("twitter_link")}
                    >
                      <BsTwitterX />
                    </Link>
                  </li> */}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center bg-[#002b42] h-12 mt-5 tracking-wider text-white font-thin leading-5  text-sm">
        {" "}
        Copyright © -{" "}
        <a href="https://weblash.az" target="_blank">
          Weblash group
        </a>
      </div>
    </div>
  );
};

export default Footer;
