import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import API from "../../API";

const ServicesCard = () => {
  const [services, setServices] = useState([]);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getAllServices = () => {
    API.Services.index().then((res) => {
      setServices([...res?.data?.data?.data]);
    });
  };

  useEffect(() => {
    getAllServices();
  }, []);

  return (
    <Slider {...settings}>
      {services &&
        services.map((service) => (
          <div key={service._id}>
            <div className="flex flex-col border rounded-md p-6 mr-4 bg-white">
              <div className="serviceimage w-full h-52 mb-7">
                <Link to={`/service/${service?._id}`}>
                  <img
                    className="w-full h-full object-fill rounded-md"
                    src={`${process.env.REACT_APP_BASE_URL}${service?.image}`}
                    alt=""
                  />
                </Link>
              </div>
              <Link to={`/service/${service?._id}`}>
                <h2 className="text-black font-bold text-xl hover:text-amber-400">
                  {service?.title.length > 25
                    ? service?.title.slice(0, 25) + "..."
                    : service?.title}
                </h2>
              </Link>
            </div>
          </div>
        ))}
    </Slider>
  );
};

export default ServicesCard;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        display: "block",
        background: "white",
        padding: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        color: "red",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        textAlign: "center",
      }}
      onClick={onClick}
    >
      <h1
        style={{
          color: "#e87813",
          margin: "0",
          position: "relative",
          right: "10px",
          fontSize: "18px",
        }}
      >
        {">"}
      </h1>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        display: "block",
        background: "white",
        padding: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
      }}
      onClick={onClick}
    >
      <h1
        style={{
          color: "#e87813",
          margin: "0",
          position: "relative",
          right: "10px",
          fontSize: "18px",
        }}
      >
        {"<"}
      </h1>
    </div>
  );
}
