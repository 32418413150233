import React from "react";
import ServicesCard from "./ServicesCard";
import { Languages } from "../../Config";

const innerText = Languages.SelectLanguage();

const ServicesComponent = () => {
  return (
    <div className="bg__blue py-12">
      <div className="container">
        <div className="content flex flex-col items-center mb-14">
          <h1 className="font-bold text-[32px] text-white text-center mb3">
            {innerText?.["Xidmətlər"]}
          </h1>
        </div>
        <div className="services">
          <ServicesCard />
        </div>
      </div>
    </div>
  );
};

export default ServicesComponent;
