import { Axios } from "../Config";

const Index = {
  index: (data) =>
    Axios.get(`/api/subCategories/index`, { params: { ...data } }).then(
      (res) => res
    ),
  show: (id) => Axios.get(`/api/subCategories/show/${id}`).then((res) => res),
  list: (data) =>
    Axios.get(`/api/subCategories/list`, { params: { ...data } }).then(
      (res) => res
    ),
};

export default Index;
