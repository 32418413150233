import React from "react";
import mapsvg from "../assets/images/mapVector.svg";
import { useNavigate } from "react-router-dom";
import { Languages } from "../Config";
const innerText = Languages.SelectLanguage();

const ContactComponent = () => {
  const navigate = useNavigate();

  return (
    <div className="bg__blue h-[282px] relative">
      <img
        className="absolute -z-0 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        src={mapsvg}
        alt=""
      />
      <div className="content flex flex-col justify-center h-full items-center pb-20">
        <h2 className="text-white font-bold text-3xl ">
          {" "}
          {innerText?.["Bizimlə əlaqə"]}
        </h2>
        <button
          onClick={() => navigate("/contact")}
          className="btn__main flex items-center !absolute bottom-10 hover:bg-[#fff] "
        >
          {innerText?.["Bizimlə əlaqə"]}
        </button>
      </div>
    </div>
  );
};

export default ContactComponent;
