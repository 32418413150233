import React, { useRef } from "react";
import { Languages } from "../../Config";
import { useNavigate } from "react-router-dom";
import { CiSearch } from "react-icons/ci";

const innerText = Languages.SelectLanguage();

const HeaderUpper = ({ settingsFilter }) => {
  const searchRef = useRef();
  const navigate = useNavigate();
  const mail = settingsFilter("mail");
  const telefon1 = settingsFilter("telefon1");

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      productSearch();
    }
  };

  const productSearch = () => {
    const value = searchRef.current.value;
    if (value) navigate(`/product/${value}`);
    else {
      navigate("/products");
    }
    searchRef.current.value = "";
  };

  return (
    <div className="bg__blue py-2">
      <div className="container font-normal text-base sm:h-10 items-center text-white flex max-sm:gap-2 gap-4 justify-end max-sm:flex-col max-sm:py-2">
        <div>
          <input
            placeholder={innerText?.["mehsulaxtar"]}
            className="border-[1px] border-solid	border-black rounded-md px-4 py-2 ml-4 bg-transparent"
            onKeyPress={handleKeyPress}
            ref={searchRef}
          />
          <button
            className="relative right-6 top-1 text-xl"
            onClick={productSearch}
          >
            <CiSearch />
          </button>
        </div>
        <span>
          <span>Email: </span> {mail}
        </span>
        <span>
          <span>Tel: </span>
          {telefon1}
        </span>
      </div>
    </div>
  );
};

export default HeaderUpper;
