import { Axios } from "../Config";

const Index = {
  index: () => Axios.get(`/api/serviceCategories/index`).then((res) => res),
  show: (id) =>
    Axios.get(`/api/serviceCategories/show/${id}`).then((res) => res),
  list: () => Axios.get(`/api/serviceCategories/list`).then((res) => res),
};

export default Index;
