import content from "./Language";

let language = localStorage.language || process.env.REACT_APP_LANGUAGE;

const languages = [
  { code: "az", name: "Azərbaycan" },
  { code: "eng", name: "English" },
];

const ChangeLanguage = (lang) => {
  localStorage.setItem("language", lang);
  // const { href } = window.location;
  window.open("/", "_parent");
};

const SelectLanguage = () => {
  return content[language];
};

export default {
  content,
  language,
  languages,
  ChangeLanguage,
  SelectLanguage,
};
