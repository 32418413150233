import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logoSvg from "../../assets/images/logo.svg";
import { FiChevronDown } from "react-icons/fi";
import MenuDrawer from "./MenuDrawer";
import HeaderLangs from "./HeaderLangs";
import API from "../../API";
import { Languages } from "../../Config";

const innerText = Languages.SelectLanguage();

const Header = () => {
  const [categories, setCategories] = useState([]);
  const [serviceCategories, setServiceCategories] = useState([]);
  const navigate = useNavigate();

  const navigateHandler = (catId) => {
    navigate(`/products/${catId}`);
  };

  const getAllCategories = () => {
    API.ProductCategories.index().then((res) => {
      setCategories([...res?.data?.data]);
    });
  };

  const getAllServiceCategories = () => {
    API.ServicCategories.index().then((res) => {
      setServiceCategories([...res?.data?.data]);
    });
  };

  useEffect(() => {
    getAllCategories();
    getAllServiceCategories();
  }, []);

  return (
    <div className="h-20  flex items-center">
      <div className="container h-full  ">
        <div className="grid grid-cols-3 max-lg:grid-cols-3 w-full h-full">
          <div className="logo h-full flex items-center ">
            <Link to="/">
              <img className="w-[72px] h-[72px]" src={logoSvg} alt="" />
            </Link>
          </div>
          <dir className="md:hidden flex items-center w-full col-span-2 justify-end">
            <MenuDrawer />
          </dir>
          <nav className="col-span-2 flex items-center max-lg:col-span-2 max-md:hidden h-full">
            <ul className="flex  justify-between w-full capitalize h-full gap-1">
              <Link
                className="flex items-center relative  h-full group "
                to="/"
              >
                {innerText?.["Əsas səhifə"]}
              </Link>
              <Link
                className="flex items-center relative  h-full group "
                to="/about"
              >
                {innerText?.["Haqqımızda"]}
              </Link>
              <Link
                className="flex items-center relative  h-full group "
                to="/accreditations"
              >
                {innerText?.["accreditations_page"]}
              </Link>
              <Link
                className="flex items-center relative  h-full group "
                to="/services"
              >
                {innerText?.["Xidmətlər"]}
                <FiChevronDown className={`ml-2 `} />
                <div
                  className={` border-t-blue-500 absolute w-max px-5 py-3  h-max  border bg-white border-[#e3e3e3] top-[100%] left-0 hidden group-hover:block hover:!block z-20`}
                >
                  <ul>
                    {serviceCategories &&
                      serviceCategories.map((scategory) => (
                        <Link
                          key={scategory?._id}
                          // onClick={(e) => navigateHandler2(scategory.id)}
                          to={`/services/${scategory._id}`}
                          className="transition-all  duration-200 hover:text-[#3a85ad] flex flex-col  mb-3 cursor-pointer normal-case	"
                        >
                          {scategory?.title}
                        </Link>
                      ))}
                  </ul>
                </div>
              </Link>
              <li className="flex items-center relative  h-full group ">
                <Link to="/products">{innerText?.["Məhsullar"]}</Link>
                <FiChevronDown className={`ml-2 `} />
                <div
                  className={` border-t-blue-500 absolute w-max px-5 py-3  h-max  border bg-white border-[#e3e3e3] top-[100%] left-0 hidden group-hover:block hover:!block z-20`}
                >
                  <ul>
                    {categories &&
                      categories.map((category) => (
                        <li
                          onClick={(e) => navigateHandler(category?._id)}
                          className="transition-all  duration-200 hover:text-[#3a85ad]  mb-3 cursor-pointer normal-case	"
                        >
                          {category?.title}
                        </li>
                      ))}
                  </ul>
                </div>
              </li>
              <Link
                className="flex items-center relative  h-full group "
                to="/blogs"
              >
                {innerText?.["Blogs"]}
              </Link>
              <Link
                className="flex items-center relative  h-full group "
                to="/contact"
              >
                {innerText?.["Əlaqə"]}
              </Link>
              <HeaderLangs />
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Header;
