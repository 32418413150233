import Home from "../pages/Home.jsx";
import React, { lazy, Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";
import HeaderUpper from "../components/Header/HeaderUpper.jsx";
import Header from "../components/Header/Header.jsx";
import Footer from "../components/Footer/Footer.jsx";
import ScrollToTop from "../components/ScrollToTop.js";
import API from "../API";
const ServiceCategories = lazy(() => import("../pages/ServiceCategories.jsx"));
const ProductsList = lazy(() => import("../pages/Products/ProductsList.jsx"));
const NotFound = lazy(() => import("../pages/NotFound.jsx"));
const Contact = lazy(() => import("../pages/Contact.jsx"));
const About = lazy(() => import("../pages/About.jsx"));
const Accreditations = lazy(() => import("../pages/Accreditations.jsx"));
const Services = lazy(() => import("../pages/Services.jsx"));
const Products = lazy(() => import("../pages/Products/Products.jsx"));
const Blogs = lazy(() => import("../pages/Blogs/Blogs.jsx"));
const BlogDetail = lazy(() => import("../pages/Blogs/BlogDetail.jsx"));
const ProductDetails = lazy(() =>
  import("../pages/Products/ProductDetails.jsx")
);
const SearchedProducts = lazy(() =>
  import("../pages/Products/SearchedProducts.jsx")
);
const ServiceDetails = lazy(() => import("../pages/ServiceDetails.jsx"));

const Layout = () => {
  const [settings, setSettings] = useState([]);

  const getSettings = async () => {
    API.Setting.index().then((res) => {
      setSettings([...res?.data?.data]);
    });
  };

  const settingsFilter = (keyword) => {
    if (settings.filter((s) => s.keyword === keyword)[0]?.image) {
      return `${process.env.REACT_APP_BASE_URL}${
        settings.filter((s) => s?.keyword === keyword)?.[0]?.image
      }`;
    } else {
      return settings.filter((s) => s?.keyword === keyword)?.[0]?.value;
    }
  };

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <div className="layout__app">
      <Suspense>
        <BrowserRouter>
          <ScrollToTop />

          <HeaderUpper settingsFilter={settingsFilter} />
          <Header />
          <Routes>
            <Route
              path="/"
              element={<Home settingsFilter={settingsFilter} />}
            />
            <Route
              path="/about"
              element={<About settingsFilter={settingsFilter} />}
            />
            <Route path="/accreditations" element={<Accreditations />} />
            <Route path="/services/:category" element={<Services />} />
            <Route path="/services" element={<ServiceCategories />} />
            {/* products  */}
            <Route path="/products" element={<Products />} />
            <Route path="/products/:category" element={<ProductsList />} />
            <Route path="/product/:search" element={<SearchedProducts />} />

            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blogs/:id" element={<BlogDetail />} />

            <Route
              path="/products/:category/:id"
              element={<ProductDetails />}
            />
            <Route path="/service/:id" element={<ServiceDetails />} />

            <Route
              path="/contact"
              element={<Contact settingsFilter={settingsFilter} />}
            />
            <Route path="/*" element={<NotFound />} />
          </Routes>
          <Footer settingsFilter={settingsFilter} />
        </BrowserRouter>
      </Suspense>
    </div>
  );
};

export default Layout;
