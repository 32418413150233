import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import API from "../../API";
import { Languages } from "../../Config";

const innerText = Languages.SelectLanguage();

const ProductBanner = () => {
  const [categories, setCategories] = useState([]);

  const getAllCategories = () => {
    API.ProductCategories.index().then((res) => {
      setCategories([...res?.data?.data]);
    });
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  return (
    <div className="container">
      <h1 className="font-bold text-[28px] text-[#121212] text-center mb3">
        {innerText?.["Məhsullar"]}
      </h1>
      <div className="grid max-md:grid-cols-1 grid-cols-2 h-auto py-5 gap-x-3">
        {categories &&
          categories.slice(0, 2).map((category) => (
            <div className="flex flex-col bg-[#003049]" key={category?._id}>
              <div className="serviceimage w-full h-64 bg-white">
                <Link to={`/products/${category?._id}`}>
                  <img
                    className="w-full h-full object-cover"
                    src={`${process.env.REACT_APP_BASE_URL}${category?.image}`}
                    alt=""
                  />
                </Link>
              </div>
              <Link to={`/products/${category?._id}`} className="py-4 pl-3">
                <h2 className="text-white font-bold text-xl hover:text-amber-400">
                  {category?.title}
                </h2>
              </Link>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ProductBanner;
